import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContactInfoSection from '../molecules/ContactInfoSection';
import CategoryFilter from '../organisms/CategoryFilter';
import Button from '../atoms/Button';

const MyPage = () => {
  const [contactInfo, setContactInfo] = useState({ name: '', email: '' });
  const [selectedFilters, setSelectedFilters] = useState({
    language: [],
    framework: [],
    librarie: [],
    db: [],
    environment: [],
    experience: [],
    time_commit: [],
    motivation: [],
  });

  const [initialContactInfo, setInitialContactInfo] = useState(null);
  const [initialFilters, setInitialFilters] = useState(null);
  const [propsErrors, setPropsErrors] = useState({ name: '', email: '' }); // エラー状態の管理
  const [errorMessage, setErrorMessage] = useState(''); // エラーメッセージの管理

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const authRandomId = sessionStorage.getItem('randomId');
        if (!authRandomId) return;

        const response = await axios.post('/api/user/user', {
          authRandomId: sessionStorage.getItem('randomId'),
        });

        const userData = response.data;
        const initialContact = {
          name: userData.contact?.name || '',
          email: userData.contact?.email || '',
        };

        const initialFilter = {
          language: userData.filters?.language || [],
          framework: userData.filters?.framework || [],
          librarie: userData.filters?.librarie || [],
          db: userData.filters?.db || [],
          environment: userData.filters?.environment || [],
          experience: userData.filters?.experience || [],
          time_commit: userData.filters?.time_commit || [],
          motivation: userData.filters?.motivation || [],
        };

        setContactInfo(initialContact);
        setSelectedFilters(initialFilter);
        setInitialContactInfo(initialContact);
        setInitialFilters(initialFilter);
      } catch (error) {
        console.error('ユーザー情報取得に失敗しました:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // propsErrorsが変更されたときにエラーメッセージを更新
    const hasErrors = Object.values(propsErrors).some(error => error !== '');
    if (hasErrors) {
      setErrorMessage('適切ではない入力項目があります。');
    } else {
      setErrorMessage(''); // エラーがない場合、エラーメッセージを消す
    }
  }, [propsErrors]); // propsErrorsが変更される度に実行

  // 変更を保存する関数
  const saveChanges = async () => {
    // propsErrors のすべての値が '' でない場合、エラーを表示
    const hasErrors = Object.values(propsErrors).some(error => error !== '');

    if (hasErrors) {
      setErrorMessage('適切ではない入力項目があります。');
      return;
    }

    try {
      const response = await axios.post('/api/user/update', {
        contactInfo,
        selectedFilters,
        authRandomId: sessionStorage.getItem('randomId'),
      });

      if (response.status === 200) {
        alert('変更が保存されました！');
        setInitialContactInfo(contactInfo);
        setInitialFilters(selectedFilters);
        setErrorMessage(''); // 成功時はエラーメッセージをクリア
      } else {
        alert('変更の保存に問題が発生しました。');
      }
    } catch (error) {
      console.error('変更保存に失敗しました:', error);
      alert('エラーが発生しました。もう一度お試しください。');
    }
  };

  return (
    <div className="flex flex-col items-center mb-6">
      <h1 className="text-3xl font-semibold mt-2 mb-4">マイページ</h1>

      {/* 連絡先情報の編集セクション */}
      <ContactInfoSection 
        contactInfo={contactInfo} 
        setContactInfo={setContactInfo} 
        setPropsErrors={setPropsErrors}
      />

      {/* カテゴリフィルターの編集セクション */}
      <CategoryFilter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />

      {/* エラーメッセージ */}
      {errorMessage && (
        <div className="text-red-500 mb-4">{errorMessage}</div>
      )}

      {/* 変更を保存ボタン */}
      <Button
        type="button"
        onClick={saveChanges}
        label="変更を保存"
        className="bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200"
      />
    </div>
  );
};

export default MyPage;
