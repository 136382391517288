import React, { useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import { UserIcon } from "@heroicons/react/solid";

const ChatMessage = ({ chats, providedRandomId, onBack }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [authRandomId, setAuthRandomId] = useState('');
  const [pollingActive, setPollingActive] = useState(true); // ポーリングの状態を管理
  const messagesEndRef = useRef(null);

  const sortedMessages = useMemo(
    () => messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
    [messages]
  );

  useEffect(() => {
    const storedAuthRandomId = sessionStorage.getItem('randomId');
    if (storedAuthRandomId) {
      setAuthRandomId(storedAuthRandomId);
    }
  }, []);

  useEffect(() => {
    if (chats?.messages) {
      setMessages(chats.messages);
    }
  }, [chats]);

  // ポーリングの処理
  const fetchMessages = async (force = false) => {

    if (!pollingActive && !force) {
      return;
    }

    try {
      const response = await axios.post('/api/chat/polling', {
        groupId: chats.groupId,
        authRandomId: sessionStorage.getItem('randomId'),
      });

      if (response.data && response.data.messages) {
        const receivedMessages = Array.isArray(response.data.messages)
          ? response.data.messages
          : [response.data.messages];

        setMessages(prevMessages => {
          const newMessages = receivedMessages.filter(
            newMsg => !prevMessages.some(prevMsg => prevMsg.timestamp === newMsg.timestamp)
          );
          return [...prevMessages, ...newMessages];
        });
      }
    } catch (error) {
      console.error('メッセージ取得エラー:', error);
    }
  };

  useEffect(() => {
    if (pollingActive) {
      const pollingInterval = setInterval(() => fetchMessages(), 10000);
      return () => clearInterval(pollingInterval);
    }
  }, [pollingActive, chats.groupId]);

  // メッセージ送信処理
  const handleSendMessage = async () => {
    if (message.trim()) {
      const timestamp = new Date().toISOString();
      const newMessage = {
        message,
        timestamp,
        user_id: authRandomId,
      };

      // ポーリングを一時停止
      setPollingActive(false);

      // 入力欄をすぐにクリア
      setMessage('');

      // 送信中のメッセージを UI に反映
      setMessages(prevMessages => [...prevMessages, newMessage]);

      try {
        await axios.post('/api/chat/sendMessage', {
          message,
          groupId: chats.groupId,
          senderId: authRandomId,
        });
      } catch (error) {
        console.error('メッセージ送信エラー:', error);
      } finally {
        setPollingActive(true); // 送信後にポーリングを再開
      }
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getHours()}時${date.getMinutes()}分`;
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="min-h-screen flex flex-col">
      {/* メッセージ領域 */}
      <div className="flex-1 overflow-y-auto p-4 max-h-screen">
        {sortedMessages.length === 0 ? (
          <p className="text-center text-gray-500">チャットを始めよう！</p>
        ) : (
          sortedMessages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${msg.user_id === authRandomId ? 'justify-end' : 'justify-start'} mb-4 items-center`}
            >
              {msg.user_id !== authRandomId && (
                chats.icon ? (
                  <img src={chats.icon} alt="user-icon" className="w-10 h-10 rounded-full mr-2" />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center mr-2">
                    <UserIcon className="w-6 h-6 text-gray-500 stroke-2" />
                  </div>
                )
              )}
              <div className="flex items-center">
                {msg.user_id === authRandomId && (
                  <span className="text-xs text-gray-500 mr-2">{formatTimestamp(msg.timestamp)}</span>
                )}

                <div className={`max-w-xs px-4 py-2 rounded-lg shadow-2xl border border-gray-200 ${msg.user_id === authRandomId ? 'bg-yellow-300 text-black' : 'bg-gray-300 text-gray-800'}`}>
                  <p>{msg.message}</p>
                </div>

                {msg.user_id !== authRandomId && (
                  <span className="text-xs text-gray-500 ml-2">{formatTimestamp(msg.timestamp)}</span>
                )}
              </div>
            </div>
          ))
        )}
        <div ref={messagesEndRef} /> {/* ここでスクロール位置を制御 */}
      </div>

      {/* 送信フィールド */}
      <div className="p-4 bg-white shadow-md bottom-0 left-0 w-full">
        <div className="flex items-center">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="flex-1 border rounded p-2"
            placeholder="メッセージを入力..."
          />
          <button onClick={handleSendMessage} className="bg-blue-500 text-white px-4 py-2 ml-2 rounded">
            送信
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
