import React, { useState, useRef } from "react";
import axios from "axios";
import Button from "../atoms/Button";
import AvatarModal from "../molecules/AvatarModal";
import { CloudDownloadIcon } from "@heroicons/react/solid";

const SignUpModal = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState(null);
  const [preview, setPreview] = useState(""); // プレビュー用の状態
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  
  const fileInputRef = useRef(null); // useRefを追加

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*+=-])[A-Za-z\d!@#$%^&*+=-]{8,}$/;
    return passwordPattern.test(password);
  };

  const validateInputs = () => {
    const errors = {};
    if (!email.trim()) errors.email = "メールアドレスを入力してください。";
    else if (!validateEmail(email)) errors.email = "正しいメールアドレスを入力してください。";
    
    if (!password.trim()) errors.password = "パスワードを入力してください。";
    else if (!validatePassword(password)) errors.password = "パスワードは8文字以上で、英字・数字・記号（!@#$%^&*+=-）をそれぞれ1つ以上含めてください。";
    
    if (!name.trim()) errors.name = "名前を入力してください。";
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const signUpSubmitHandler = async () => {
    if (!validateInputs()) return;

    const formData = new FormData();
    formData.append("email", email.trim());
    formData.append("password", password.trim());
    formData.append("name", name.trim());

    if (icon) formData.append("icon", icon);

    formData.forEach((value, key) => {
      console.log("出力値", key, value);
    });

    try {
      const response = await axios.post("/api/auth/signup", formData);

      const { randomId, user } = response.data;
      sessionStorage.setItem('randomId', randomId);
      sessionStorage.setItem('user', JSON.stringify(user));
      onClose();
      window.location.reload();
    } catch (err) {
      setError(err.response?.data?.message || "新規登録に失敗しました。");
    }
  };

  const handleIconClick = () => fileInputRef.current.click();

  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIcon(file);
      setPreview(URL.createObjectURL(file)); // プレビュー画像を設定
    }
  };

  const handleIconDelete = () => {
    setIcon(null);
    setPreview(""); // プレビューをクリア
  };

  return (
    <div className="flex h-full flex-1 flex-col p-0">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          新規作成
        </h2>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* アイコンアップロード */}
          <div className="flex flex-col items-center mt-6">
            <div
              className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center cursor-pointer overflow-hidden border"
              onClick={handleIconClick}
            >
              {preview ? (
                <img src={preview} alt="avatar" className="w-full h-full object-cover" />
              ) : (
                <CloudDownloadIcon className="w-10 h-10 text-gray-500" />
              )}
            </div>
            <input type="file" ref={fileInputRef} className="hidden" onChange={handleIconUpload} />
            {icon && (
              <button
                type="button"
                className="text-red-500 text-sm mt-2"
                onClick={handleIconDelete}
              >
                削除
              </button>
            )}
          </div>

          {/* 入力フォーム */}
          <div className="mt-4">
            <label htmlFor="name" className="block text-base font-medium text-gray-700">
              名前
            </label>
            <input
              id="name"
              name="name"
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border border-gray-400 mt-1 block w-full rounded-md shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-base"
            />
            {validationErrors.name && <p className="text-red-500">{validationErrors.name}</p>}
          </div>

          <div className="mt-8">
            <label htmlFor="email" className="block text-base font-medium text-gray-700">
              メールアドレス
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-400 mt-1 block w-full rounded-md shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-base"
            />
            {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}
          </div>

          <div className="mt-4">
            <label htmlFor="password" className="block text-base font-medium text-gray-700">
              パスワード
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border border-gray-400 mt-1 block w-full rounded-md shadow-md focus:border-indigo-500 focus:ring-indigo-500 text-base"
            />
            {validationErrors.password && <p className="text-red-500">{validationErrors.password}</p>}
          </div>

          <div className="mt-8">
            <Button
              type="button"
              label="サインアップ"
              onClick={signUpSubmitHandler}
              className="w-full bg-yellow-300 mb-16 text-white py-2 px-4 rounded-md shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpModal;
