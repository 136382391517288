import React, { useState, useEffect } from 'react';
import InputField from '../atoms/InputField';

// 入力値のサニタイズ処理
const sanitizeInput = (input) => {
  return input.trim().replace(/<\/?[^>]+(>|$)/g, ""); 
};

// 名前のバリデーション
const validateName = (name) => {
  return name.trim() !== '';
};

// メールアドレスのバリデーション
const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

const ContactInfoSection = ({ contactInfo, setContactInfo, setPropsErrors }) => {
  // 各フィールドのエラーメッセージを保持
  const [errors, setErrors] = useState({ name: '', email: '' });

  useEffect(() => {
    // 初期表示時に親コンポーネントから渡されたcontactInfoを設定
    setContactInfo(contactInfo);
  }, [contactInfo, setContactInfo]);

  const handleNameChange = (e) => {
    const name = sanitizeInput(e.target.value);
    setContactInfo({ ...contactInfo, name });

    if (!validateName(name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: '名前は必須です。',
      }));
      setPropsErrors((prevErrors) => ({
        ...prevErrors,
        name: '名前は必須です。',
      }));
    } else if (name.length > 20) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: '名前は20文字以内で入力してください。',
      }));
      setPropsErrors((prevErrors) => ({
        ...prevErrors,
        name: '名前は20文字以内で入力してください。',
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
      setPropsErrors((prevErrors) => ({ ...prevErrors, name: '' }));
    }
  };

  const handleEmailChange = (e) => {
    const email = sanitizeInput(e.target.value);
    setContactInfo({ ...contactInfo, email });
  };

  const handleEmailBlur = () => {
    const email = contactInfo.email;
    if (!validateEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '正しいメールアドレスを入力してください。',
      }));
      setPropsErrors((prevErrors) => ({
        ...prevErrors,
        email: '正しいメールアドレスを入力してください。',
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
      setPropsErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }
  };

  return (
    <div className="w-full max-w-2xl mb-6 p-6 rounded-lg shadow-lg border bg-white border-gray-300">
      <h2 className="text-xl font-semibold mb-6">連絡先情報</h2>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
          <label
            htmlFor="name"
            className="w-full sm:w-32 text-sm font-semibold text-gray-700 text-center sm:text-left"
          >
            名前
          </label>
          <InputField
            id="name"
            type="text"
            placeholder="名前を入力"
            value={contactInfo.name}
            onChange={handleNameChange}
            maxLength={20}
          />
        </div>
        <div className="flex justify-end">
          {errors.name && <p className="text-red-500 text-xs mt-1 justify-end">{errors.name}</p>}
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
          <label
            htmlFor="email"
            className="w-full sm:w-32 text-sm font-semibold text-gray-700 text-center sm:text-left"
          >
            メールアドレス
          </label>
          <InputField
            id="email"
            type="email"
            placeholder="メールアドレスを入力"
            value={contactInfo.email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
          />
        </div>
        <div className="flex justify-end">
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
        </div>
      </div>
    </div>
  );
};

export default ContactInfoSection;
